// Custom components
import React, { useEffect, useRef } from "react";

function InputField(props) {
  const { label, id, extra, type, placeholder, variant, state, disabled, onChange , width , labelColor} =
    props;


    const inputRef = useRef(null);

    useEffect(() => {
      const inputElement = inputRef.current;
  
      const handleWheel = (event) => {
        if (document.activeElement === inputElement) {
          event.preventDefault();
        }
      };
  
      inputElement.addEventListener('wheel', handleWheel);
  
      return () => {
        inputElement.removeEventListener('wheel', handleWheel);
      };
    }, []);
  

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm ${labelColor ? labelColor : "text-GreatifyChalkGreen-500"} ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-1 font-medium"
        }` }
      >
        {label}
      </label>
      <input
       ref={inputRef}
        disabled={disabled}
        type={type}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        className={`mt-2 flex h-12 ${width ? width : "w-full"} items-center justify-center rounded-md border bg-white/0 p-3 text-sm outline-none ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-200 dark:!border-white/10 dark:text-white"
        }`}
      />
    </div>
  );
}

export default InputField;
