// import Footer from "components/footer/FooterAuthDefault";
// import authImg from "assets/img/auth/auth.png";
import authImg from "assets/img/auth/login-image.png";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";

export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative h-screen w-full  bg-white">
        
        <div className="flex h-screen w-full justify-center bg-center">
          <img src={authImg} className="w-fit h-full " alt="Auth" />
          <div className="flex justify-center items-center w-full h-full ">
          <Routes>
            {getRoutes(routes)}
            <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
          </Routes>
        </div>
        </div>
      
        
      </div>
    </div>
  );
}
