import React, { useState } from "react";
import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import axios from "../../axiosConfig"; // Import the configured Axios instance
import { Link, useNavigate } from "react-router-dom";

export default function SignIn() {
  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post("loginapi", {
        username,
        password,
      });
      console.log(response);

      if (response.data.status === "success") {
        // Save token and role to localStorage
        localStorage.setItem("username", username);
        localStorage.setItem("password", password);
        localStorage.setItem("id", response.data.data.id);
        localStorage.setItem("role", response.data.data.role.toLowerCase()); // Assume response contains user role
        // Redirect based on role
        if (response.data.data.role === "Admin") {
          navigate("/admin/default");
        } else if (response.data.data.role === "Teacher") {
          navigate("/teacher/default"); // Define teacher routes similarly
        }
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("An error occurred during sign-in.");
    }
  };

  return (
  
      <div className="w-[500px]  flex-col items-center rounded-md bg-white p-8  ">
        <h4 className="text-2xl font-bold text-GreatifyChalkGreen-500 ">
          Login
        </h4>
        <p className="pt-2 pb-4 text-base text-GreatifyChalkGreen-500">
        Access dashboard by entering your registered 
        user ID and password.
        </p>
        {/* <div className=" flex h-[50px] w-full cursor-pointer items-center justify-center gap-2 rounded-md bg-lightPrimary">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-GreatifyChalkGreen-500">
            Sign In with Google
          </h5>
        </div>
        <div className="flex items-center gap-3 pb-4 pt-4">
          <div className="h-px w-full bg-gray-200 " />
          <p className="text-base text-gray-600 "> or </p>
          <div className="h-px w-full bg-gray-200 " />
        </div> */}
         <form onSubmit={handleSignIn} className="flex flex-col">
          <div className="mb-4">
            <label htmlFor="username" className="text-sm block text-GreatifyNeutral-400 mb-1">
              User ID
            </label>
            <input
              type="text"
              id="username"
                
              value={username}
              onChange={(e) => setusername(e.target.value)}
              className="w-full px-3 py-2 border text-GreatifyChalkGreen-500 border-GreatifyNeutral-200 rounded-md focus:outline-GreatifyPurple-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="text-sm block text-GreatifyNeutral-400 mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
            
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border text-GreatifyChalkGreen-500 border-GreatifyNeutral-200 rounded-md focus:outline-GreatifyPurple-500"
              required
            />
          </div>
          {error && <p className="text-red-500">{error}</p>}
        <Link to="/auth/forgot-password" className="text-sm self-end font-medium text-GreatifyPurple-500 " >
            Forgot Password?
          </Link>
       
          <div className="flex items-center justify-between  mt-4">
            <button
              type="submit"
              className="bg-GreatifyPurple-500 w-full font-medium text-white px-4 py-2 rounded-md"
            >
              Login
            </button>
          </div>
       
        </form>
        <div className="mt-4">
          <span className=" text-sm text-GreatifyChalkGreen-500">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-GreatifyPurple-500  "
          >
            Create an account
          </a>
        </div>
      </div>
     
  );
}
