const Radio = (props) => {
  const { color, id, name, ...rest } = props;
  return (
    <input
      id={id}
      name={name}
      type="radio"
      className={`before:contet[""] relative h-5 w-5 cursor-pointer appearance-none rounded-full
       border checked:!border-GreatifyGreen-500 checked:before:!bg-GreatifyGreen-500  !border-GreatifyGreen-500  transition-all duration-[0.2s] before:absolute before:top-[3px]
       before:left-[50%] before:h-3 before:w-3 before:translate-x-[-50%] before:rounded-full before:transition-all before:duration-[0.2s] dark:!border-gray-800
      `}
      {...rest}
    />
  );
};

export default Radio;
