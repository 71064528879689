import React, { useEffect, useState } from "react";
import axios from "../../../axiosConfig";
import { Link, useNavigate } from "react-router-dom";
import "tailwindcss/tailwind.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { PacmanLoader } from "react-spinners";
import { Oval } from "react-loader-spinner";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";

import Dropdown from "components/dropdown";
import { EllipsisVerticalIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import ExportAs from "components/popupmodal/exportAs";

const ExamViewById = () => {
  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const examId = new URLSearchParams(window.location.search).get("examid");
  const subject = new URLSearchParams(window.location.search).get("subject");
  const [buttonStates, setButtonStates] = useState({});
  const [filenameModal, setFilenameModal] = useState(false);

  useEffect(() => {
    const fetchExams = async () => {
      try {
        setLoading(true); // Set loading to true at the start of the API call
        const response = await axios.get(`student-results?examid=${examId}`);
        setExams(response.data);
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error("Error fetching exam list:", error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchExams();
    // const interval = setInterval(fetchExams,30000); // Fetch data every 10 seconds

    // return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [examId]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const AIEvaluate = async (studentId) => {
    console.log(examId);
    setButtonStates((prevStates) => ({ ...prevStates, [examId]: "loading" }));

    try {
      const response = await axios.post(`/ai_evaluate_php`, {
        exam_id: examId,
        student_id: studentId,
      });
      console.log(response.data);
      if (response.data.status === 200) {
        NotificationManager.success("Success", "AI Evaluation");
      } else {
        NotificationManager.warning("Warning", "AI Evaluation", 3000);
      }
      setButtonStates((prevStates) => ({ ...prevStates, [examId]: "success" }));
    } catch (error) {
      console.error("Error evaluating exam:", error);
      NotificationManager.error("Error", error.message, 5000, () => {
        alert("callback");
      });
      setButtonStates((prevStates) => ({ ...prevStates, [examId]: "error" }));
    }
  };

  const filteredExams = exams.filter((exam) => {
    return (
      exam.student_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filterStatus ? exam.registration_no === filterStatus : true)
    );
  });

  const uniqueStatuses = [
    ...new Set(exams.map((exam) => exam.registration_no)),
  ];

  const handleViewClick = (exam) => {
    if (!exam.ai_completed_status) {
      NotificationManager.warning(
        "Warning",
        "Please wait until the evaluation is completed.",
        3000
      );
      return;
    }
  };

  const CsvHeaders = [
    { label: "Id", key: "id" },
    { label: "Name", key: "student_name" },
    { label: "Register No", key: "registration_no" },
    { label: "No of Questions", key: "noofquestions" },
    { label: "Attended", key: "Attended" },
    { label: "Evaluated", key: "complete_evaluation" },
    { label: "Evaluation Pending", key: "pending_evaluation" },
    { label: "Marks Obtained", key: "mark_obtained" },
  ];

  const csvData = filteredExams?.map((exam, index) => ({
    id: index + 1,
    student_name: exam.student_name,
    registration_no: exam.registration_no,
    noofquestions: exam.noofquestions,
    Attended: exam.Attended,
    complete_evaluation: exam.complete_evaluation,
    pending_evaluation: exam.pending_evaluation,
    mark_obtained: exam.mark_obtained,
  }));

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "Id",
          "Name",
          "Register No",
          "No of Questions",
          "Attended",
          "Evaluated",
          "Evaluation Pending",
          "Marks Obtained",
        ],
      ],
      body: filteredExams.map((exam, index) => [
        index + 1,
        exam.student_name,
        exam.registration_no,
        exam.noofquestions,
        exam.Attended,
        exam.complete_evaluation,
        exam.pending_evaluation,
        exam.mark_obtained,
      ]),
      columnStyles: {
        0: { cellWidth: 10 }, // Id
        1: { cellWidth: 40 }, // Name
        2: { cellWidth: 30 }, // Register No
        3: { cellWidth: 20 }, // No of Questions
        4: { cellWidth: 20 }, // Attended
        5: { cellWidth: 20 }, // Evaluated
        6: { cellWidth: 20 }, // Evaluation Pending
        7: { cellWidth: 20 }, // Marks Obtained
      },
      headStyles: { fontSize: 8 },
      styles: { overflow: "linebreak" },
    });
    doc.save("exam-results.pdf");
  };
  const navigate = useNavigate()
  return (
    <div className="py-4">
      <NotificationContainer />
      <div className="flex items-center pb-4">
        <button
           onClick={() => navigate(-1)}
          className="flex items-center text-blue-500 hover:underline"
        >
          <svg
            className="mr-1 h-5 w-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
          Back
        </button>
        <h1 className="ml-4 text-2xl font-bold">Students List</h1>
      </div>
      <div className="mb-4 flex items-center justify-between gap-8">
        <div className="flex gap-4">
          <input
            type="text"
            placeholder="Search by student name"
            value={searchTerm}
            onChange={handleSearchChange}
            className="rounded border px-4 py-2"
          />
          <select
            value={filterStatus}
            onChange={handleStatusFilterChange}
            className="rounded border px-4 py-2 "
          >
            <option value="">Register No</option>
            {uniqueStatuses.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>

        {/* export options */}

        <Dropdown
          button={
            <div className="h-10 w-10 cursor-pointer rounded-md border border-GreatifyNeutral-200 bg-white p-2 text-GreatifyChalkGreen-500">
              <EllipsisVerticalIcon className="h-full w-full" />
            </div>
          }
          children={
            <div className=" flex  w-auto  min-w-[140px] flex-col divide-y divide-gray-200 overflow-hidden rounded-md border border-GreatifyNeutral-200 bg-white py-2 shadow-md ">
              <button
                onClick={generatePDF}
                className="px-4  py-2 text-left text-GreatifyChalkGreen-500 "
              >
                Export PDF
              </button>
              <CSVLink
                data={csvData}
                headers={CsvHeaders}
                filename="student_results.csv"
                className=" py-2   px-4 text-GreatifyChalkGreen-500  "
              >
                Export CSV
              </CSVLink>
              <button
                onClick={() => setFilenameModal(true)}
                className="px-4  py-2 text-left text-GreatifyChalkGreen-500 "
              >
                Export All Answers PDF
              </button>
            </div>
          }
          classNames={"py-2 top-10 right-0 w-max"}
        />

        <ExportAs
          open={filenameModal}
          onClose={() => setFilenameModal(false)}
        />
      </div>

      {loading ? (
        <div className="flex h-full items-center justify-center">
          <PacmanLoader size={50} color={"#00da49"} loading={loading} />
        </div>
      ) : filteredExams.length === 0 ? (
        <div className="text-center text-gray-500">No records found</div>
      ) : (
        <div class="mt-4 h-full  w-full overflow-x-auto  rounded-md  ">
          <div class="w-fit max-w-full rounded-md bg-GreatifyChalkGreen-500">
            <table
              id="example"
              class="h-auto w-full table-fixed  divide-y divide-gray-200  rounded-md"
            >
              <thead className="">
                <tr className="text-left text-xs font-medium uppercase tracking-wider text-GreatifyChalkGreen-100">
                  <th className="w-[5%]  px-6 py-3 ">Id</th>
                  <th className="w-[20%] px-6 py-3">Name</th>
                  <th className="w-[10%] px-6 py-3 ">Register No</th>
                  <th className="w-[10%] px-6 py-3 ">No of Questions</th>
                  <th className="w-[10%] px-6 py-3">Attended</th>
                  <th className="w-[10%] px-6 py-3">Evaluated</th>
                  <th className="w-[10%] px-6 py-3">Evaluation Pending</th>
                  <th className="w-[10%] px-6 py-3">Marks Obtained</th>
                  <th className="w-[15%] px-6 py-3">AI Evaluate</th>
                  <th className="w-[10%] px-6 py-3">Action</th>
                </tr>
              </thead>
              <tbody className="w-full divide-y divide-gray-200  whitespace-nowrap  bg-white  ">
                {filteredExams.map((exam, index) => (
                  <tr key={exam.id} className="text-GreatifyChalkGreen-500 ">
                    <td className="px-6 py-4 ">{index + 1}</td>
                    <td className="px-6 py-4 ">{exam.student_name}</td>
                    <td className="px-6 py-4 ">{exam.registration_no}</td>
                    <td className="px-6 py-4 ">{exam.noofquestions}</td>
                    <td className="px-6 py-4 ">{exam.Attended}</td>
                    <td className="px-6 py-4 ">{exam.complete_evaluation}</td>
                    <td className="px-6 py-4 ">{exam.pending_evaluation}</td>
                    <td className="px-6 py-4 ">{exam.mark_obtained}</td>
                    <td className="px-6 py-4 ">
                      <button
                        className="rounded bg-blue-500 px-4 py-2 text-white"
                        onClick={() => AIEvaluate(exam.student_id)}
                        disabled={buttonStates[exam.id] === "loading"}
                      >
                        {buttonStates[exam.id] === "loading" ? (
                          <Oval
                            height={20}
                            width={20}
                            color="#ffffff"
                            ariaLabel="loading"
                          />
                        ) : (
                          "AI Evaluate"
                        )}
                      </button>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      {exam.ai_completed_status ? (
                        <Link
                          to={`/teacher/aieval?examid=${examId}&subject=${subject}&studentid=${exam.student_id}`}
                          className="text-blue-500 hover:underline"
                        >
                          View
                        </Link>
                      ) : (
                        <button
                          onClick={() => handleViewClick(exam)}
                          className="text-gray-500"
                        >
                          Wait
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamViewById;
