import React, { useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { motion } from "framer-motion";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const [sideBarResize, setSideBarResize] = useState(false);
  
  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" || prop.layout === "/teacher" || prop.layout === '/teacherview') {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex w-full min-h-screen  gap-4 ">
      <div > 
      <Sidebar open={open} onClose={() => setOpen(false)} sideBarResize={sideBarResize} setSideBarResize={setSideBarResize} />
      </div>
        {/* Main Content */}
          {/* Routes */}
          <motion.div   initial={{ width: !sideBarResize ? "calc(100% - 276px)" : "calc(100% - 106px)", marginLeft: !sideBarResize ? "260px" :"90px"}}
           animate={{ width: !sideBarResize ? "calc(100% - 276px)" : "calc(100% - 106px)", marginLeft: !sideBarResize ? "260px" :"90px"}}
           transition={{ duration: 0.2 }} className={` py-4 pr-4 min-h-screen`}>
            <div className="bg-GreatifyNeutral-100 overflow-hidden border border-GreatifyNeutral-200 h-full w-full rounded-md flex flex-col justify-between ">
              <div>
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"Horizon UI Tailwind React"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="px-4 ">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
            </div>
           
              <Footer />
            
            </div>
          </motion.div>
        
      </div>
  );
}
