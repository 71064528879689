import React, { useEffect, useState } from "react";
import axios from "../../../axiosConfig";
import { Link } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { Oval } from "react-loader-spinner";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const ExamList = ({type}) => {
  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonStates, setButtonStates] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSubject, setFilterSubject] = useState("");
  const [filterYearSemester, setFilterYearSemester] = useState("");

  useEffect(() => {
    const fetchExams = async () => {
      try {
        setLoading(true); // Set loading to true at the start of the API call
        const response = await axios.get(
          `/exam-list?id=${localStorage.getItem("id")}`
        );
        setExams(response.data);
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error("Error fetching exam list:", error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchExams();
  }, []);

  const AIEvaluate = async (examId) => {
    setButtonStates((prevStates) => ({ ...prevStates, [examId]: "loading" }));

    try {
      const response = await axios.post(`/ai_evaluate_php`, {
        exam_id: examId,
      });
      console.log(response.data);
      if (response.data.status === 200) {
        NotificationManager.success("Success", "AI Evaluation");
      } else {
        NotificationManager.warning("Warning", "AI Evaluation", 3000);
      }
      setButtonStates((prevStates) => ({ ...prevStates, [examId]: "success" }));
    } catch (error) {
      console.error("Error evaluating exam:", error);
      NotificationManager.error("Error", error.message, 5000, () => {
        alert("callback");
      });
      setButtonStates((prevStates) => ({ ...prevStates, [examId]: "error" }));
    }
  };

  const ImageExtract = async (examId) => {
    setButtonStates((prevStates) => ({ ...prevStates, [examId]: "loading" }));

    try {
      const response = await axios.post(`/image_extract`, { exam_id: examId });
      console.log(response.data);
      if (response.data.status === 200) {
        NotificationManager.success("Success", "AI Evaluation");
      } else {
        NotificationManager.warning("Warning", "AI Evaluation", 3000);
      }
      setButtonStates((prevStates) => ({ ...prevStates, [examId]: "success" }));
    } catch (error) {
      console.error("Error evaluating exam:", error);
      NotificationManager.error("Error", error.message, 5000, () => {
        alert("callback");
      });
      setButtonStates((prevStates) => ({ ...prevStates, [examId]: "error" }));
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubjectFilterChange = (event) => {
    setFilterSubject(event.target.value);
  };

  const handleYearSemesterFilterChange = (event) => {
    setFilterYearSemester(event.target.value);
  };

  const filteredExams = exams.filter((exam) => {
    return (
      exam.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filterSubject ? exam.subjectname === filterSubject : true) &&
      (filterYearSemester ? exam.class_name === filterYearSemester : true)
    );
  });

  const uniqueSubjects = [...new Set(exams.map((exam) => exam.subjectname))];
  const uniqueYearSemesters = [
    ...new Set(exams.map((exam) => exam.class_name)),
  ];

  return (
    <div className="py-4">
      <NotificationContainer />
      <h1 className="text-2xl font-bold">Exam List</h1>
      <div className="pt-4 flex gap-4">
        <input
          type="text"
          placeholder="Search by exam name"
          value={searchTerm}
          onChange={handleSearchChange}
          className="rounded border px-4 py-2 "
        />
        <select
          value={filterSubject}
          onChange={handleSubjectFilterChange}
          className="rounded border px-4  py-2"
        >
          <option value="">All Subjects</option>
          {uniqueSubjects.map((subject) => (
            <option key={subject} value={subject}>
              {subject}
            </option>
          ))}
        </select>
        <select
          value={filterYearSemester}
          onChange={handleYearSemesterFilterChange}
          className="rounded border px-4 py-2"
        >
          <option value="">All Year-Semesters</option>
          {uniqueYearSemesters.map((yearSemester) => (
            <option key={yearSemester} value={yearSemester}>
              {yearSemester}
            </option>
          ))}
        </select>
      </div>
      {loading ? (
        <div className="flex h-full items-center justify-center">
          <PacmanLoader size={50} color={"#00da49"} loading={loading} />
        </div>
      ) : filteredExams.length === 0 ? (
        <div className="text-center text-gray-500">No records found</div>
      ) : (
        <div class="mt-4 h-full  w-full overflow-x-auto  rounded-md  ">
         
            <div class="w-fit min-w-full rounded-md bg-GreatifyChalkGreen-500">
              <table class="w-full h-auto  divide-y divide-gray-200  rounded-md">
                <thead className="">
                  <tr className="text-left text-xs font-medium uppercase tracking-wider text-GreatifyChalkGreen-100">
                    <th className="px-6 py-3 ">S.No</th>
                    <th className="px-6 py-3 ">Exam Name</th>
                    <th className="px-6 py-3 ">Exam Date</th>
                    <th className="px-6 py-3 ">Subject</th>
                    <th className="px-6 py-3 ">Year-Semester</th>
                    <th className="px-6 py-3 ">Degree</th>
                    <th className="px-6 py-3 ">{type == "results" ? "Results" : "Exam View"}</th>
                    {/* <th className="px-6 py-3 ">Image Extract</th> */}
                   {type !== "results" &&  <th className="px-6 py-3 ">Actions</th> }
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200   bg-white  w-full whitespace-nowrap   ">
                  {filteredExams.map((exam, index) => (
                    <tr key={exam.id} className=" text-GreatifyChalkGreen-500 ">
                      <td className="px-6 py-4 w-[5%]">{index + 1}</td>
                      <td className="px-6 py-4 w-[15%]">{exam.name}</td>
                      <td className="px-6 py-4  w-[10%] ">{exam.datentime}</td>
                      <td className="px-6 py-4 w-[15%]">{exam.subjectname}</td>
                      <td className="px-6 py-4  w-[15%]">{exam.class_name}</td>
                      <td className="px-6 py-4 w-[20%] ">{exam.degree}</td>
                      <td className="px-6 py-4 w-[10%] whitespace-nowrap">
                      {type == "results" ?  <Link
                        to={`/teacher/student_results?examid=${exam.id}`}
                          className="text-blue-500 hover:underline"
                        >
                        View Result
                        </Link> :  <Link
                        to={`/teacher/examlist?examid=${exam.id}&subject=${encodeURIComponent(exam.subjectname)}`}
                          className="text-blue-500 hover:underline"
                        >
                        View Exam
                        </Link> }
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                      onClick={() => ImageExtract(exam.id)}
                      disabled={buttonStates[exam.id] === 'loading'}
                    >
                      {buttonStates[exam.id] === 'loading' ? (
                        <Oval
                          height={20}
                          width={20}
                          color="#ffffff"
                          ariaLabel="loading"
                        />
                      ) : (
                        'S3 Image Generate'
                      )}
                    </button>
                  </td> */}
                        {type !== "results" &&   <td className="whitespace-nowrap px-6 py-4 w-[10%]">
                        <button
                          className="rounded bg-blue-500 px-4 py-2 text-white"
                          onClick={() => AIEvaluate(exam.id)}
                          disabled={buttonStates[exam.id] === "loading"}
                        >
                          {buttonStates[exam.id] === "loading" ? (
                            <Oval
                              height={20}
                              width={20}
                              color="#ffffff"
                              ariaLabel="loading"
                            />
                          ) : (
                            "AI Evaluate"
                          )}
                        </button>
                      </td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
     
      )}
    </div>


  );
};

export default ExamList;
