/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import routes from "routes.js";
import { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/16/solid";
import Logo from "./components/greatifyLogo.png";
import SmallLogo from "./components/logo.png";
import LogoMark from "./components/Logo mark.svg";
import { motion } from "framer-motion";

const Sidebar = ({ open, onClose, sideBarResize, setSideBarResize }) => {
  const role = localStorage.getItem("role");

  // Filter routes based on role
  const filteredRoutes = routes.filter((route) => {
    if (role === "admin") {
      return route.layout === "/admin";
    } else if (role === "teacher") {
      return route.layout === "/teacher";
    }
    return false;
  });
  console.log(filteredRoutes);

  const handleSideBar = () => {
    setSideBarResize(!sideBarResize);
    localStorage.setItem("sideBarResize", !sideBarResize);
  };

  return (
    <motion.div
      initial={{ width: "auto" }}
      animate={{ width: "auto" }}
      transition={{ duration: 0.2 }}
      className="fixed h-full  p-4"
    >
      <motion.div
        initial={{ width: sideBarResize ? "70px" : "240px" }}
        animate={{ width: sideBarResize ? "70px" : "240px" }}
        className={`z-2  flex h-full  flex-col  justify-between rounded-lg bg-GreatifyChalkGreen-500 text-lg  ${
          open ? "translate-x-0" : "-translate-x-96"
        }`}
        transition={{ duration: 0.2 }}
      >
        <div>
          {/* Sidebar logo */}
          <div className="relative m-2 flex h-[72px] items-center justify-center  rounded-md bg-GreatifyChalkGreen-400">
            <img
              src={!sideBarResize ? Logo : SmallLogo}
              alt="logo"
              className="h-7 w-auto"
            />

            <div
              onClick={handleSideBar}
              className="absolute top-1/2 -right-5 flex h-6 w-6  -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-GreatifyChalkGreen-400 "
            >
              {!sideBarResize ? (
                <ChevronLeftIcon className="absolute right-[3px] h-4 w-4 rounded-full bg-GreatifyGreen-500 text-GreatifyChalkGreen-500 " />
              ) : (
                <ChevronRightIcon className="absolute right-[3px] h-4 w-4 rounded-full bg-GreatifyGreen-500 text-GreatifyChalkGreen-500 " />
              )}
            </div>
          </div>

          {/* Nav item */}

          <div className="flex w-full flex-col  gap-2 overflow-hidden pt-4 ">
            <Links routes={filteredRoutes} sideBarResize={sideBarResize} />
          </div>
        </div>

        {/* Nav item end */}
        <div className="overflow-hidden ">
          <img
            src={LogoMark}
            alt="logomark"
            className={`${sideBarResize ? "w-[50px]" : "h-[100px]"} w-auto `}
          />
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Sidebar;
