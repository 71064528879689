import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from '../axiosConfig'; // Import the configured Axios instance

const ProtectedRoute = ({ role }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null means not checked yet
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('username');
        if (!token) {
          setIsAuthenticated(false);
          return;
        }

        const response = await axios.post('loginapi', { 'username': localStorage.getItem('username'), 'password': localStorage.getItem('password') });

        if (response.data.status === 'success') {
          setIsAuthenticated(true);
          setUserRole(localStorage.getItem('role'));
        } else {
          setIsAuthenticated(false);
          localStorage.removeItem('username');
          localStorage.removeItem('password');
          localStorage.removeItem('role');
        }
      } catch (error) {
        setIsAuthenticated(false);
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        localStorage.removeItem('role');
      }
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    // Optionally render a loading indicator while checking authentication
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  if (role && userRole !== role) {
    return <Navigate to={`/${userRole}/default`} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
