/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";


export function SidebarLinks({ routes, sideBarResize }) {
  // Chakra color mode
  let location = useLocation();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl" ||
        route.layout === "/teacher"
      ) {
        return (
          <div className="flex w-full items-center justify-between pl-4">
            <Link
              key={index}
              to={route.layout + "/" + route.path}
              className={`flex ${
                activeRoute(route.path) === true
                  ? "text-GreatifyCreme-500"
                  : "text-gray-300 hover:text-GreatifyCreme-500 "
              } w-full items-center whitespace-nowrap  p-2 font-poppins text-sm `}
            >
              <span
                className={`mr-2 inline-block h-6 w-6 ${
                  activeRoute(route.path) === true && "text-GreatifyGreen-500"
                }`}
              >
                {route.icon ? route.icon : <DashIcon />}{" "}
              </span>

              {!sideBarResize && route.name}
            </Link>
            {!sideBarResize && activeRoute(route.path) && (
              <div className="h-10 w-[8px] bg-[#00DC46] rounded-s-md "></div>
            )}
          </div>
        );
      }
    });
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;