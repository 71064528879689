import React, { useEffect, useRef, useState } from "react";
import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import axios from "../../axiosConfig"; // Import the configured Axios instance
import { Link, useNavigate } from "react-router-dom";

export default function EmailVerification() {
  const [otp, setOtp] = useState(['', '', '', '']);
  const inputRefs = useRef([]);

  const handleInputChange = (index, e) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    // Move focus to the next input box
    if (e.target.value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    // Move focus to the previous input box on backspace
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleOTP =  () => {
      navigate("/auth/change-password")
  };


  return (
  
      <div className="w-[500px]  flex-col items-center rounded-md bg-white p-8  ">
        <h4 className="text-2xl font-bold text-GreatifyChalkGreen-500 ">
          Email Verification
        </h4>
        <p className="pt-2 pb-4 text-base text-GreatifyChalkGreen-500">
        Enter the 4-digit verification code that was sent to your Email. Didn't receive code? <a href="#" class="text-blue-500 hover:underline">Resend</a>.
        </p>
         <form onSubmit={handleOTP} className="flex flex-col">
          <div className="mb-4 mt-4 flex gap-4 ">
            {otp.map((digit, index) => (
            <input
              key={index}
              type="number"
              value={digit}
              onChange={(e) => handleInputChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onWheel={(e) => e.target.blur()}
              ref={(el) => (inputRefs.current[index] = el)}
              className="w-12 h-12 p-1 text-xl font-bold border text-center text-GreatifyChalkGreen-500 border-GreatifyNeutral-200 rounded-md focus:outline-GreatifyPurple-500"
              maxLength="1"
              autoFocus={index === 0}
              required
            />
          ))}
          </div>
          {error && <p className="text-red-500">{error}</p>}
      
       
          <div className="flex flex-col gap-4 items-center justify-between  mt-4">
            <button
              type="submit"
              className="bg-GreatifyPurple-500 w-full font-medium text-white px-4 py-2 rounded-md"
            >
              Submit
            </button>
            <Link to="/auth/sign-in" 
              className=" w-full text-sm text-center text-GreatifyChalkGreen-500 px-4 py-2 rounded-md"
            >
             Back to login
            </Link>
          </div>
       
        </form>
      </div>
     
  );
}
