import { Fragment, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/16/solid";
import Vector from "./assets/image.png";
import InputField from "components/fields/InputField";
import InputTextField from "components/fields/TextField";

export default function ManualEvaluationModal({ open, onClose, mark, setMark, reason, setReason, onSave }) {

  const handlesave=()=>{
    if(mark){
    onSave(mark, reason)
    }
  }
  
  return (
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={onClose}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="relative flex items-center  justify-between border-b p-4  ">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900 "
                  >
                    Manual Evaluation
                  </DialogTitle>
                  <div onClick={onClose} className=" z-10 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full  border bg-white shadow-sm">
                    <XMarkIcon className="h-4 w-4" />
                  </div>

                  <img
                    src={Vector}
                    className="z-1 absolute top-0 right-0 h-full "
                  />
                </div>
                <div className="mt-2 p-4">
                  <InputField
                    extra="mb-3"
                    label="MARK FOR THE ANSWER"
                    placeholder="03"
                    id="mark"
                    type="number"
                    value={mark}
                    width={"w-[300px]"}
                    labelColor={"text-GreatifyNeutral-500"}
                    onChange={(e) => setMark(e.target.value)}
                  />
                         <InputTextField
                    extra="mb-3"
                    label="YOUR FEEDBACK"
                    placeholder=""
                    id="feedback"
                    type="text"
                   labelColor={"text-GreatifyNeutral-500"}
                    rows={4}
                    value={reason}
                    width={"w-[300px]"}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </div>

                <div className="px-4 py-3  flex flex-row gap-4 justify-end border-t">
              
              <button onClick={onClose} className="flex w-32 items-center  font-medium justify-center rounded-md border border-GreatifyNeutral-200 py-2 text-GreatifyChalkGreen-500 ">
                cancel
              </button>

              <button onClick={()=>handlesave()} className="flex w-32 items-center  font-medium  justify-center rounded-md  bg-GreatifyGreen-500 py-2 text-white ">
                Submit
              </button>
       
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
