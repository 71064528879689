import { Fragment, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/16/solid";
import Vector from "./assets/image.png";
import InputField from "components/fields/InputField";
import InputTextField from "components/fields/TextField";
import axios from "./../../axiosConfig";
import { closeSnackbar, enqueueSnackbar } from "notistack";

export default function ExportAs({ open, onClose }) {
  const [fileName, setFileName] = useState("student_results");
  const examId = new URLSearchParams(window.location.search).get("examid");
  const roleId = localStorage.getItem("id");

  const message = (msg, bg, text) => {
    enqueueSnackbar(msg, {
      autoHideDuration: 2000,
      variant: "default",
      style: {
        backgroundColor: bg,
        color: text,
        padding: "8px 16px",
        borderRadius: "4px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      },
      action: (key) => (
        <div
          onClick={() => closeSnackbar(key)}
          className="h-6 w-6 cursor-pointer rounded-md p-[2px] text-white hover:bg-GreatifyChalkGreen-200"
        >
          <XMarkIcon className="h-full w-full" aria-hidden="true" />
        </div>
      ),
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const handleExportZip = () => {
    onClose();

    axios
      .get(
        `get_student_ai_results_pdf_to_zip?examId=${examId}&name=${fileName}&teacher_id=${roleId}`
      )
      .then((response) => {
        message(
          "Exporting... The download will start soon.",
          "#00373A",
          "white"
        );
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  };

  return (
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={onClose}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="relative flex items-center  justify-between border-b p-4  ">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900 "
                  >
                    Download File
                  </DialogTitle>
                  <div
                    onClick={onClose}
                    className=" z-10 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full  border bg-white shadow-sm"
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </div>

                  <img
                    src={Vector}
                    className="z-1 absolute top-0 right-0 h-full "
                  />
                </div>
                <div className="mt-2 p-4">
                  <InputField
                    extra="mb-3"
                    label="Save as"
                    placeholder="Enter filename"
                    id="filename"
                    type="text"
                    value={fileName}
                    labelColor={"text-GreatifyNeutral-500"}
                    onChange={(e) => setFileName(e.target.value)}
                  />
                </div>

                <div className="flex flex-row  justify-end gap-4 border-t px-4 py-3">
                  <button
                    onClick={onClose}
                    className="flex w-32 items-center  justify-center rounded-md border border-GreatifyNeutral-200 py-2 font-medium text-GreatifyChalkGreen-500 "
                  >
                    cancel
                  </button>

                  <button
                    onClick={handleExportZip}
                    className="flex w-32 items-center  justify-center  rounded-md bg-GreatifyGreen-500  py-2 font-medium text-white "
                  >
                    Submit
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
