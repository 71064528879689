import React, { useEffect, useState } from "react";
import axios from "../../../axiosConfig";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { PacmanLoader } from "react-spinners";
import Dropdown from "components/dropdown";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

const StudentResults = ({ examid }) => {
  const [data, setData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const examId = new URLSearchParams(window.location.search).get("examid");
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [showAIMark, setShowAIMark]=useState(true)
  useEffect(() => {
    setLoading(true);
    const fetchResults = async () => {
      try {
        const response = await axios.get(showAIMark ? `get_exam_ai_results/${examId}` : `get_exam_results/${examId}`);
        setData(response.data.ques_status);
        setQuestions(response.data.ques_count_array);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data", error);
      }
    };

    fetchResults();
  }, [examid, showAIMark]);

  const renderIcon = (data) => {
    if (data === "YES") {
      return <i className="fa fa-check text-2xl text-green-500"></i>;
    } else if (data === "NO") {
      return <i className="fa fa-times text-2xl text-red-500"></i>;
    } else {
      return (
        <i className="fa fa-exclamation-triangle text-base text-yellow-500">
          {data}
        </i>
      );
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const filteredStudents = data.filter((student) => {
    return (
      student.student_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filterStatus ? student.registration_no === filterStatus : true)
    );
  });

  const generatePDF = () => {
    const doc = new jsPDF();
    const tableColumn = [
      "Name",
      "Reg No",
      "Total Mark",
      ...questions.map((_, index) => `Q - ${index + 1}`),
    ];
    const tableRows = [];

    data.forEach((student) => {
      const studentData = [
        student.student_name,
        student.registration_no,
        student.total_mark,
        ...questions.map((_, qIndex) => student[`Q${qIndex + 1}`]),
      ];
      tableRows.push(studentData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      theme: "grid",
    });
    doc.text("Students Results Question Wise", 14, 15);
    doc.save("student_results.pdf");
  };

  const headers = [
    { label: "Name", key: "student_name" },
    { label: "Reg No", key: "registration_no" },
    { label: "Total Mark", key: "total_mark" },
    ...questions.map((_, index) => ({
      label: `Q - ${index + 1}`,
      key: `Q${index + 1}`,
    })),
  ];

  const uniqueStatuses = [...new Set(data.map((exam) => exam.registration_no))];



  return (
    <div className="container mx-auto py-4">
      <div className="flex justify-between gap-8 items-center ">
        <h1 className="text-2xl font-bold">StudentResults</h1>
        <div className="w-fit bg-white flex gap-2 rounded-md p-1 border font-medium">
          <p onClick={()=> setShowAIMark(true)} className={`px-4 py-2 rounded-md ${showAIMark ? "border-GreatifyChalkGreen-500 bg-GreatifyChalkGreen-500 text-white" : "border-GreatifyNeutral-500"} border w-[130px] text-center cursor-pointer`}>AI Mark</p>
          <p onClick={()=> setShowAIMark(false)} className={`px-4 py-2 rounded-md ${!showAIMark ? "bborder-GreatifyChalkGreen-500 bg-GreatifyChalkGreen-500 text-white" : "border-GreatifyNeutral-500"} border w-[130px] text-center cursor-pointer`}>Manual Mark</p>
        </div>
      </div>

      <div className="my-4 flex items-center justify-between gap-8">
        <div className="flex gap-4">
          <input
            type="text"
            placeholder="Search by student name"
            value={searchTerm}
            onChange={handleSearchChange}
            className="rounded border px-4 py-2"
          />
          <select
            value={filterStatus}
            onChange={handleStatusFilterChange}
            className="rounded border px-4 py-2 "
          >
            <option value="">Register No</option>
            {uniqueStatuses.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>

        {/* export options */}

        <Dropdown
          button={
            <div className="h-10 w-10 cursor-pointer rounded-md border border-GreatifyNeutral-200 bg-white p-2 text-GreatifyChalkGreen-500">
              <EllipsisVerticalIcon className="h-full w-full" />
            </div>
          }
          children={
            <div className=" flex  w-auto  min-w-[140px] flex-col divide-y divide-gray-200 overflow-hidden rounded-md border border-GreatifyNeutral-200 bg-white py-2 shadow-md ">
              <button
                onClick={generatePDF}
                className="px-4  py-2 text-left text-GreatifyChalkGreen-500 "
              >
                Export PDF
              </button>
              <CSVLink
                data={data}
                headers={headers}
                filename="student_results.csv"
                className=" py-2   px-4 text-GreatifyChalkGreen-500  "
              >
                Export CSV
              </CSVLink>
            </div>
          }
          classNames={"py-2 top-10 right-0 w-max"}
        />
      </div>

      {loading ? (
        <div className="flex h-full items-center justify-center">
          <PacmanLoader size={50} color={"#00da49"} loading={loading} />
        </div>
      ) : filteredStudents?.length === 0 ? (
        <div className="text-center text-gray-500">No records found</div>
      ) : (
        <div class="mt-4 h-full  w-full overflow-x-auto  rounded-md  ">
          <div class="w-full rounded-md bg-GreatifyChalkGreen-500">
            <table class="h-auto w-full  divide-y divide-gray-200 rounded-md  bg-GreatifyChalkGreen-500">
              <thead>
                <tr className="text-left text-xs font-medium uppercase tracking-wider text-GreatifyChalkGreen-100">
                  <th className=" whitespace-nowrap py-3 px-4">Name</th>
                  <th className=" py-3 px-4">Reg No</th>
                  <th className=" py-3 px-4">Total Mark</th>
                  {questions.map((_, index) => (
                    <th key={index} className="whitespace-nowrap py-3 px-4">
                      Q - {index + 1}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="w-full divide-y   divide-gray-200  bg-white  ">
                {filteredStudents?.map((student, index) => (
                  <tr key={index} className="text-GreatifyChalkGreen-500 ">
                    <td className=" whitespace-nowrap py-3 px-4">
                      {student.student_name}
                    </td>
                    <td className=" py-3 px-4">{student.registration_no}</td>
                    <td className=" py-3 px-4">{student.total_mark}</td>
                    {questions.map((_, qIndex) => {
                      const questionKey = `Q${qIndex + 1}`;
                      return (
                        <td key={qIndex} className=" py-3 px-4 text-sm">
                          {renderIcon(student[questionKey])}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentResults;
