// Custom components
import React from "react";

function InputField(props) {
  const { label, id, extra, placeholder, cols, rows, state, disabled, labelColor,onChange} = props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`ml-1 text-sm font-medium ${labelColor ? labelColor : "text-GreatifyChalkGreen-500"} `}
      >
        {label}
      </label>
      <div>
        <textarea
          cols={cols}
          rows={rows}
          placeholder={placeholder}
          className={`mt-2 flex w-full items-center justify-center rounded-md border  p-2 text-sm outline-none `}
          name={id}
          id={id}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default InputField;
