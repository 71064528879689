import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';


import RtlLayout from 'layouts/rtl';
import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import ProtectedRoute from './middleware/ProtectedRoute'; // Assuming ProtectedRoute is implemented in a separate file
import { Notifications } from 'react-push-notification';

const App = () => {
  <Notifications />

  return (
    <Routes >
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<ProtectedRoute role="admin" />}>
        <Route path="*" element={<AdminLayout />} />
      </Route>
      <Route path="teacher/*" element={<ProtectedRoute role="teacher" />}>
        <Route path="*" element={<AdminLayout />} />
      </Route>
      <Route path="rtl/*" element={<ProtectedRoute role="rtl" />}>
        <Route path="*" element={<RtlLayout />} />
      </Route>
      <Route path="/" element={<Navigate to="/auth" replace />} />
    </Routes>
  );
};

export default App;
