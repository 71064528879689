import axios from 'axios';

// Create an Axios instance with default settings
const axiosInstance = axios.create({
  baseURL: 'https://examx.co.in/api/', // Set the base URL from environment variables
  headers: {
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('token') ? `Token ${localStorage.getItem('token')}` : null
  },
  // withCredentials: true, // Include this to ensure cookies are sent with requests
});

// Optionally, you can add interceptors to handle requests and responses
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify request configuration here (e.g., add authorization headers)
    // config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Handle responses here (e.g., transform response data)
    return response;
  },
  (error) => {
    // Handle errors here (e.g., redirect to login on 401 response)
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
