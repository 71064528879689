import React, { useEffect, useState } from "react";
import axios from "../../../axiosConfig";
import { Link } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { Oval } from "react-loader-spinner";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import InputField from "components/fields/InputField";
import { Pagination, PaginationItem } from "@mui/material";

const PDFDownloads = () => {
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(
          `get_list_zip_pdf_cron?teacher_id=${localStorage.getItem("id")}`
        );
        setFiles(response.data);
        setLoading(false);

        const allStatusesAreOne = response.data.every(
          (file) => file.status === 1
        );
        if (allStatusesAreOne) {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Error fetching PDF files:", error);
        setLoading(false);
      }
    };

    setLoading(true);
    fetchFiles();
    const intervalId = setInterval(fetchFiles, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const filteredFiles = files.filter(
      (file) =>
        file.exam_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        file.file_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredFiles(filteredFiles);
  }, [files, searchTerm]);

  useEffect(() => {
    const currentPageFiles = (filteredFiles ? filteredFiles : files).slice(
      (page - 1) * perPage,
      page * perPage
    );
    setPageData(currentPageFiles);
  }, [page, filteredFiles, files]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="py-4">
      <NotificationContainer />
      <div className="flex gap-4 ">
        <input
          type="text"
          placeholder="Filter by exam or file name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-[300px] rounded-md border px-4 py-2"
        />
      </div>

      {loading ? (
        <div className="flex h-full items-center justify-center">
          <PacmanLoader size={50} color={"#00da49"} loading={loading} />
        </div>
      ) : files.length === 0 ? (
        <div className="text-center text-gray-500">No records found</div>
      ) : (
        <div className="mt-4 h-full w-full overflow-x-auto rounded-md">
          <div className="w-full rounded-md bg-GreatifyChalkGreen-500">
            <table className="h-auto w-full divide-y divide-gray-200 rounded-md">
              <thead className="">
                <tr className="text-left text-xs font-medium uppercase tracking-wider text-GreatifyChalkGreen-100">
                  <th className="px-6 py-3">S No</th>
                  <th className="px-6 py-3">Exam ID</th>
                  <th className="px-6 py-3">Exam Name</th>
                  <th className="px-6 py-3">File Name</th>
                  <th className="px-6 py-3">Status</th>
                  <th className="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody className="w-full divide-y divide-gray-200 whitespace-nowrap bg-white">
                {pageData.length > 0 ? 
                  pageData.map((file, index) => (
                    <tr key={file.id} className="text-GreatifyChalkGreen-500">
                      <td className="px-6 py-4">{(page - 1) * perPage + index + 1}</td>
                      <td className="px-6 py-4">{file.exam_id}</td>
                      <td className="px-6 py-4">{file.exam_name}</td>
                      <td className="px-6 py-4">{file.file_name}</td>
                      <td className="px-6 py-4">{file.status == 0 ? "Pending" : file.status == 2 ? "Failed" : "Success" }</td>
                      <td className="px-6 py-4"> 
                        {file.status == 1 && (
                          <a
                            rel="noopener noreferrer"
                            href={file.file_url}
                            className="cursor-pointer text-blue-500 hover:underline"
                          >
                            Download
                          </a>
                        ) }
                      </td>
                    </tr>
                  )) : (
                  <tr>
                    <td className="px-6 py-4 text-center" colSpan={5}>No records found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="mt-8  px-4 flex justify-between items-center gap-4">
            <p className="text-sm text-GreatifyChalkGreen-500"> Showing {page} of {Math.ceil((filteredFiles ? filteredFiles : files).length / perPage)} pages </p>
            {pageData.length > 0 && (
              <Pagination
                count={Math.ceil((filteredFiles ? filteredFiles : files).length / perPage)}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    style={{
                      backgroundColor: item.page === page ? "#00373A" : "inherit",
                      color: item.page === page ? "white" : "inherit",
                      border: item.page === page ? "" : "1px solid #E4E4E7",
                    }}
                  />
                )}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFDownloads;
