import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import {
  ClipboardDocumentListIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  // MdOutlineShoppingCart,
  MdBarChart,
  MdLock,
} from "react-icons/md";
import ExamList from "./views/teacher/exam/ExamList";
import ExamViewById from "./views/teacher/exam/ExamViewByID";
import ExamResults from "./views/teacher/exam/ExamViewAIByID";
import ExamEvaluation from "./views/teacher/exam/Evaluation";


import StudentResults from "./views/teacher/student_result/StudentResults";
import SuperAdminDashboard from "views/superadmin/default";
import PDFDownloads from "views/teacher/pdf_downloads/pdf-downloads";
import ForgotPassword from "views/auth/ForgotPassword";
import EmailVerification from "views/auth/emailVerification";
import ChangePassword from "views/auth/changePassword";


const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <RectangleGroupIcon className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Main Dashboard",
    layout: "/teacher",
    path: "default",
    icon: <RectangleGroupIcon className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Fotgot Password",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgotPassword />,
  },
  {
    name: "Email Verification",
    layout: "/auth",
    path: "email-verification",
    icon: <MdLock className="h-6 w-6" />,
    component: <EmailVerification />,
  },
  {
    name: "Change Password",
    layout: "/auth",
    path: "change-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ChangePassword />,
  },
  {
    name: "Exams",
    layout: "/teacher",
    icon: <ClipboardDocumentListIcon className="h-6 w-6" />,
    path: "exams",
    component: <ExamList />,
  },
  {
    name: "ExamList",
    layout: "/teacherview",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "examlist",
    component: <ExamViewById />,
  },
  {
    name: "AI Evaluation",
    layout: "/teacherview",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "aieval",
    component: <ExamEvaluation />,
  },
  {
    name: "Student Result",
    layout: "/teacherview",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "student_results",
    component: <StudentResults />,
  },
  {
    name: "Student Result",
    layout: "/teacher",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "exam_list",
    component: <ExamList type="results" />,
  },

  {
    name: "PDF Downloads",
    layout: "/teacher",
    path: "pdf-downloads",
    icon: <RectangleGroupIcon className="h-6 w-6" />,
    component: <PDFDownloads />,
  },
 
];
export default routes;
