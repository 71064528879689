import React, { useState } from "react";
import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import axios from "../../axiosConfig"; // Import the configured Axios instance
import { Link, useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const [mail, setMail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleMail = (e) => {
  e.preventDefault()
  navigate("/auth/email-verification")
  };

  return (
  
      <div className="w-[500px]  flex-col items-center rounded-md bg-white p-8  ">
        <h4 className="text-2xl font-bold text-GreatifyChalkGreen-500 ">
          Forgot Password ?
        </h4>
        <p className="pt-2 pb-4 text-base text-GreatifyChalkGreen-500">
        Enter your email and we’ll send you a link to 
        reset your password.
        </p>
         <form onSubmit={handleMail} className="flex flex-col">
          <div className="mb-4">
            <label htmlFor="userId" className="text-sm block text-GreatifyNeutral-400 mb-1">
              Email
            </label>
            <input
              type="email"
              id="userId"
                
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              className="w-full px-3 py-2 border text-GreatifyChalkGreen-500 border-GreatifyNeutral-200 rounded-md focus:outline-GreatifyPurple-500"
              required
            />
          </div>
          {error && <p className="text-red-500">{error}</p>}
      
       
          <div className="flex flex-col gap-4 items-center justify-between  mt-4">
            <button
              type="submit"
              className="bg-GreatifyPurple-500 w-full font-medium text-white px-4 py-2 rounded-md"
            >
              Send mail
            </button>
            <Link to="/auth/sign-in" 
              className=" w-full text-sm text-center text-GreatifyChalkGreen-500 px-4 py-2 rounded-md"
            >
             Back to login
            </Link>
          </div>
       
        </form>
      </div>
     
  );
}
