import React, { useEffect, useState, useMemo, useCallback } from "react";
import axios from "../../../axiosConfig";
import { useNavigate } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import Zoom from "react-medium-image-zoom";

import Radio from "components/radio";

import { Pagination, PaginationItem } from "@mui/material";
import ManualEvaluationModal from "components/popupmodal/manualEvaluationModal";
import SubmitModal from "components/popupmodal/SubmitModal";

const ExamEvaluation = () => {
  const [selectedOption, setSelectedOption] = useState("Agree");
  const [modal, setModal] = useState(false);

  const [data, setData] = useState([]);
  const [reason, setReason] = useState("");
  const [mark, setMark] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageData, setImageData] = useState(null);
 

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const examId = params.get("examid");
  const subject =params.get("subject")
  const studentId = params.get("studentid");

  const [toggleEvaluation, setToggleEvaluation]=useState(false)

  const [page, setPage] = useState(1);

  const handleQuestionChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {

    setToggleEvaluation(false)
    console.log("first fecth")
    if(data){
    handleFetchImage(data[page - 1]?.question_image, data[page - 1]);
    }
  }, [page,data]);


  useEffect(() => {
    setLoading(true);
    axios
      .get(`student-ai-results?examId=${examId}&studentId=${studentId}`)
      .then((response) => {
        const dataWithSerial = response.data.map((item, index) => ({
          ...item,
          serial: index + 1,
        }));
        setData(dataWithSerial);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [examId, studentId, refresh]);

  const handleFetchImage = (url, data) => {
    axios.get(url)
      .then(response => {
        if (response.status) {
          const response_data = response.data;
          const output_dict = response_data.filter(
            item =>
              item.questionId === String(data.onlinequestionpaperid) &&
              item.studentId === String(data.studentid)
          );
  
          if (output_dict.length > 0) {
            const pngEncodedAnswer = output_dict[0].pngEncodedAnswer;
            if (pngEncodedAnswer) {
              const blob = b64toBlob(pngEncodedAnswer, 'image/png');
              handleConvertBlobToBase64(blob);
            }
          } else {
            console.log("No matching data found");
          }
        }
      })
      .catch(error => {
        console.error("Error fetching image:", error);
      });
  };
  
  const handleConvertBlobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      setImageData(base64data); // Function to display the image
      console.log(base64data); // Optionally log the base64 string to console
    };
  };
  
  // Helper function to convert base64 to blob
  function b64toBlob(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, {type: contentType});
  }

  const Base64Image = ({ base64Data }) => {
    const imageSrc = `${base64Data}`;
    return (
      <>
        {base64Data ? (
          <Zoom>
            <img src={imageSrc} alt="Question" className="h-full w-auto" />
          </Zoom>
        ) : (
          <p>No Image</p>
        )}
      </>
    );
  };

  const handleAgree = () => {
    setModal(false)
    setLoading(true);
    console.log("id",data[page - 1]?.testResultId)
    axios
      .post("update_mark_student_results", {
        id: data[page - 1]?.testResultId,
        agree: 1,
        mark: data[page - 1]?.aimark != null ? data[page - 1]?.aimark : 0,
        reject_reason: reason,
        status: 0,
      })
      .then((response) => {
        console.log(response.data);
        setRefresh((prev) => !prev);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error saving agreement:", error);
        setLoading(false);
      });
  }

 

  const handleDialogClose = () => {
    setModal(false);
    setReason("");
    setMark("");
  };

  const handleDialogSubmit = (mark, reason) => {
    console.log("sass", { mark, reason });
    setLoading(true);
    axios
      .post("update_mark_student_results", {
        id: data[page - 1]?.testResultId,
        agree: 0,
        reject_reason: reason,
        mark: mark,
        status: 1,
      })
      .then((response) => {
        console.log(response.data);
        handleDialogClose();
        setRefresh((prev) => !prev);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error saving disagreement:", error);
        setLoading(false);
      });
  };

  const [parsedContent, setParsedContent] = useState({});

  useEffect(() => {
    if (!data || data.length === 0 || page < 1 || page > data.length) {
      setParsedContent({});
      return;
    }

    const aicontent = data[page - 1]?.aicontent;
    let parsed = {};

    try {
      parsed = JSON.parse(aicontent || "{}");
    } catch (error) {
      console.error("Error parsing aicontent:", error);
    }

    setParsedContent(parsed);
  }, [data, page]);

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const navigate = useNavigate()



  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        < button
          onClick={() => navigate(-1)}
          className="flex items-center text-blue-500 hover:underline"
        >
          <svg
            className="mr-2 h-5 w-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
          Back
        </button>
        <h1 className="ml-4 text-2xl font-bold">Results Details</h1>
      </div>

      {loading ? (
        <div className="flex h-full items-center justify-center">
          <PacmanLoader size={50} color={"#00da49"} loading={loading} />
        </div>
      ) : data.length === 0 ? (
        <div className="text-center text-gray-500">No records found</div>
      ) : (
        <>
          <div className="flex h-[60px] w-full items-center justify-between rounded-md bg-GreatifyChalkGreen-500 p-4 text-white">
            <p className="font-medium">University</p>
            <p className="font-medium">{decodeURIComponent(subject)}</p>
            <p></p>
          </div>
          <div className="mt-4 flex w-full gap-4">
            <div className="w-[calc(100%-400px)] rounded-md bg-white  p-4">
              <div className="flex justify-between gap-4">
                <div className="flex gap-4 divide-x divide-GreatifyNeutral-400 ">
                  <p className="text-GreatifyGreen-600">
                    {" "}
                    Section B Question {data[page - 1]?.serial}{" "}
                  </p>{" "}
                  <p className="pl-4 text-GreatifyNeutral-400">
                    Descriptive Answer
                  </p>
                </div>
                <p className="text-GreatifyGreen-600">
                  {data[page - 1]?.maximumMark} MARK
                </p>
              </div>
              <div>
                <div className="flex justify-between gap-8 pt-4">
                  <p className="font-medium text-GreatifyChalkGreen-500">
                    {data[page - 1]?.question}
                  </p>
                </div>
                <div className="mt-8 h-[300px] w-full overflow-auto rounded-md border border-GreatifyGreen-500 p-4">
                  {imageData ? (
                    <Base64Image base64Data={imageData} />
                  ) : (
                    "No Image"
                  )}
                </div>
                <div className="mt-8 flex justify-center  gap-4">

                  <Pagination
                    count={data?.length}
                    page={page}
                    onChange={handleQuestionChange}
                    shape="rounded"
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        style={{
                          backgroundColor:
                            item.page === page ? "#00DC46" : "inherit",
                          color: item.page === page ? "white" : "inherit",
                          border: item.page === page ? "" : "1px solid #E4E4E7",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            {data[page - 1]?.is_agree ==0 && !toggleEvaluation ? 
            <div className=" flex   w-[400px] flex-col justify-between gap-8 rounded-md bg-white p-4">
                <div>
                  <h4 className="text-GreatifyChalkGreen-500 underline">
                    Manual Evaluation
                  </h4>
                  <p className="mt-2 w-fit rounded-md bg-GreatifyPurple-500 px-2 py-1 text-xs text-white">
                    {data[page - 1]?.providedMark
                      ? `${data[page - 1]?.providedMark} Marks`
                      : "0 Marks"}
                  </p>
                  <p className="mt-4 text-GreatifyChalkGreen-500">(Reason)</p>

                  <div className="mt-4 text-GreatifyGreen-600">
                    <p className="font-medium">
                      {data[page - 1]?.reject_reason ? data[page - 1]?.reject_reason : "No reason found"}
                    </p>
                  </div>
                </div>
                <button onClick={()=> setToggleEvaluation(true)}> View AI evaluation</button>
              </div> : 
              <div className=" flex   w-[400px] flex-col justify-between gap-8 rounded-md bg-white p-4">
                <div>
                  <h4 className="text-GreatifyChalkGreen-500 underline">
                    AI Evaluation
                  </h4>
                  <p className="mt-2 w-fit rounded-md bg-GreatifyPurple-500 px-2 py-1 text-xs text-white">
                    {data[page - 1]?.aimark
                      ? `${data[page - 1]?.aimark} Marks`
                      : "0 Marks"}
                  </p>
                  <p className="mt-4 text-GreatifyChalkGreen-500">(Keywords)</p>

                  <div className="mt-4 text-GreatifyGreen-600">
                    <p className="font-medium">{parsedContent.content}</p>
                    {parsedContent.detailed_evaluation &&
                      (typeof parsedContent.detailed_evaluation === "string" ? (
                        <p className="whitespace-pre-line text-sm">
                          {parsedContent.detailed_evaluation}
                        </p>
                      ) : (
                        <ul className="list-inside list-disc whitespace-pre-line text-sm">
                          {Object.entries(
                            parsedContent.detailed_evaluation
                          ).map(([key, val]) => (
                            <li key={key} className="truncate">
                              <p>
                                <strong>{key.replace(/_/g, " ")}:</strong>
                              </p>
                              <p>Score: {val.score}</p>
                              <p>Feedback: {val.feedback}</p>
                            </li>
                          ))}
                        </ul>
                      ))}
                  </div>
                </div>
                {data[page - 1]?.is_agree == null ? (
                  <div>
                    <div className="mt-4 flex gap-4">
                      <p className="flex items-center justify-center gap-2 font-medium text-GreatifyChalkGreen-500">
                        <Radio
                          name="option"
                          value="Agree"
                          checked={selectedOption === "Agree"}
                          onChange={handleChange}
                        />
                        Agree
                      </p>
                      <p className="flex items-center justify-center gap-2 font-medium text-GreatifyChalkGreen-500">
                        <Radio
                          name="option"
                          value="Disagree"
                          checked={selectedOption === "Disagree"}
                          onChange={handleChange}
                        />
                        Disagree
                      </p>
                    </div>
                    <button
                      onClick={() => setModal(true)}
                      className=" mt-4 flex w-40 items-center justify-center  rounded-md bg-GreatifyGreen-500 py-2 font-medium text-white"
                    >
                      Submit
                    </button>
                  </div>
                ) :  data[page - 1]?.is_agree ==0 &&  <button onClick={() => setToggleEvaluation(false)}> View Manual evaluation</button>}
              </div>
             }
          </div>
        </>
      )}
      {selectedOption == "Agree" ? (
        <SubmitModal
          onSave={handleAgree}
          open={modal}
          setOpen={() => setModal(false)}
        />
      ) : (
        <ManualEvaluationModal
          onSave={handleDialogSubmit}
          open={modal}
          onClose={() => handleDialogClose()}
          mark={mark}
          setMark={setMark}
          setReason={setReason}
          reason={reason}
        />
      )}
    </div>
  );
};

export default ExamEvaluation;
