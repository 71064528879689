import React, { useState } from "react";
import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import axios from "../../axiosConfig"; // Import the configured Axios instance
import { Link, useNavigate } from "react-router-dom";

export default function ChangePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChangePassword = async (e) => {
    e.preventDefault()
   navigate("/auth/sign-in")
  };

  return (
  
      <div className="w-[500px]  flex-col items-center rounded-md bg-white p-8  ">
        <h4 className="text-2xl font-bold text-GreatifyChalkGreen-500 ">
        Change password
        </h4>
        {/* <p className="pt-2 pb-4 text-base text-GreatifyChalkGreen-500">
        Access dashboard by entering your registered 
        user ID and password.
        </p> */}
    
         <form onSubmit={handleChangePassword} className="flex  mt-4 flex-col">
          <div className="mb-4">
            <label htmlFor="password" className="text-sm block text-GreatifyNeutral-400 mb-1">
             New Password
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full px-3 py-2 border text-GreatifyChalkGreen-500 border-GreatifyNeutral-200 rounded-md focus:outline-GreatifyPurple-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="text-sm block text-GreatifyNeutral-400 mb-1">
            Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-3 py-2 border text-GreatifyChalkGreen-500 border-GreatifyNeutral-200 rounded-md focus:outline-GreatifyPurple-500"
              required
            />
          </div>
       
          <div className="flex flex-col gap-4 items-center justify-between  mt-4">
            <button
              type="submit"
              className="bg-GreatifyPurple-500 w-full font-medium text-white px-4 py-2 rounded-md"
            >
              Submit
            </button>
            <Link to="/auth/sign-in" 
              className=" w-full text-sm text-center text-GreatifyChalkGreen-500 px-4 py-2 rounded-md"
            >
             Cancel
            </Link>
          </div>
       
        </form>
      </div>
     
  );
}
