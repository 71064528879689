const FreeCard = () => {
  return (
    // <div className="relative mt-14 flex w-[256px] justify-center rounded-md bg-gradient-to-br from-[#868CFF] via-[#432CF3] to-brand-500 pb-4">
    //   <div className="absolute -top-12 flex h-24 w-24 items-center justify-center rounded-full border-[4px] border-white bg-gradient-to-b from-[#868CFF] to-brand-500 dark:!border-navy-800">
    //     <svg
    //       width="41"
    //       height="41"
    //       viewBox="0 0 41 41"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M10.0923 27.3033H30.8176V36.3143H10.0923V27.3033Z"
    //         fill="white"
    //       />
    //       <path
    //         d="M31.5385 29.1956C31.5385 26.2322 30.3707 23.3901 28.2922 21.2947C26.2136 19.1992 23.3945 18.022 20.4549 18.022C17.5154 18.022 14.6963 19.1992 12.6177 21.2947C10.5392 23.3901 9.37143 26.2322 9.37143 29.1956L20.4549 29.1956H31.5385Z"
    //         fill="white"
    //       />
    //       <path
    //         fill-rule="evenodd"
    //         clip-rule="evenodd"
    //         d="M20.5 31.989C26.8452 31.989 31.989 26.8452 31.989 20.5C31.989 14.1548 26.8452 9.01099 20.5 9.01099C14.1548 9.01099 9.01099 14.1548 9.01099 20.5C9.01099 26.8452 14.1548 31.989 20.5 31.989ZM20.5 41C31.8218 41 41 31.8218 41 20.5C41 9.17816 31.8218 0 20.5 0C9.17816 0 0 9.17816 0 20.5C0 31.8218 9.17816 41 20.5 41Z"
    //         fill="white"
    //       />
    //     </svg>
    //   </div>

    // </div>
    <svg width="142" height="41" viewBox="0 0 142 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.28404 28.6756C6.01864 31.7237 8.0271 34.0734 11.0051 35.2753C12.262 35.783 13.6491 36.0262 15.1254 35.9978C15.3405 35.9935 15.5627 35.9836 15.7863 35.9679C19.5443 35.6991 23.1422 33.8259 25.5003 32.3011C28.4726 30.3795 31.227 27.889 33.1208 25.4213C33.2624 26.7327 33.4025 28.0469 33.4803 28.9686C33.5837 30.179 34.5291 31.1604 35.7266 31.3012C35.9346 31.3254 36.147 31.3254 36.3578 31.297C37.6982 31.1249 38.6904 29.8533 38.5701 28.4636C38.4767 27.3869 38.3182 25.9134 38.1639 24.4882L37.5765 18.1204C37.4873 17.018 36.9014 16.0494 35.97 15.4606C35.0387 14.8731 33.9149 14.7636 32.8873 15.1619C26.0848 17.8017 20.6454 19.1174 20.6016 19.1288C19.213 19.3692 18.2775 20.7005 18.5167 22.0958C18.7559 23.4911 20.0793 24.4327 21.4692 24.1923C21.6348 24.1639 24.7968 23.383 29.3941 21.8554C27.9022 23.9463 25.3955 26.2562 22.7332 27.9772C18.8054 30.5175 15.2258 31.4378 12.9117 30.5033C11.5047 29.9358 10.589 28.8975 10.2649 27.5007C9.80485 25.5222 10.5012 23.0374 12.1983 20.4302C13.3377 18.6793 14.7786 17.1489 16.4134 15.8531C18.3327 14.3298 21.2654 12.4338 24.2207 12.1821C24.9101 12.1237 25.5682 11.8307 26.024 11.3087C26.4797 10.7867 26.7048 10.1126 26.6538 9.42129C26.5491 8.01317 25.3177 6.94784 23.9122 7.05167C19.3008 7.40014 15.0829 10.3017 12.6512 12.3215C10.5678 14.051 8.77019 16.1063 7.39724 18.4461C5.80491 21.1599 4.37252 24.8864 5.28546 28.6713L5.28404 28.6756Z" fill="#00DC46"/>
<path d="M38.6054 5.00066C36.0209 5.04902 33.9572 7.20103 34.0053 9.79823C34.0535 12.3954 36.195 14.4692 38.7795 14.4208C41.364 14.3725 43.4277 12.2205 43.3796 9.62328C43.3329 7.02608 41.1899 4.95372 38.6054 5.00066Z" fill="#00DC46"/>
<path d="M71.6745 15.0326C71.1139 15.0326 70.5336 15.2089 69.9519 15.5574C69.4494 15.8575 69.0489 16.2415 68.7615 16.6967V15.2786H65.9817V27.1282H68.7601L68.7743 19.5698C69.0616 18.9867 69.4664 18.5159 69.9774 18.1702C70.4982 17.8161 70.9979 17.6369 71.465 17.6369C71.8599 17.6369 72.3199 17.7066 72.8308 17.8431L73.0106 17.8915V15.2388L72.9186 15.2032C72.627 15.0894 72.2081 15.0311 71.673 15.0311L71.6745 15.0326Z" fill="white"/>
<path d="M95.967 16.6953C95.6457 16.243 95.1701 15.859 94.5516 15.5546C93.8467 15.209 93.0966 15.0326 92.3209 15.0326C90.7399 15.0326 89.3854 15.6342 88.2941 16.8219C87.2042 18.0067 86.6522 19.476 86.6522 21.1857C86.6522 22.8953 87.2042 24.3874 88.2941 25.5736C89.3854 26.7612 90.7399 27.3629 92.3209 27.3629C93.1036 27.3629 93.8566 27.1894 94.5573 26.8466C95.173 26.545 95.6471 26.1639 95.967 25.7116V27.1282H98.7582V15.2787H95.967V16.6953ZM95.9656 19.4333V22.9935C95.6882 23.4799 95.2593 23.8867 94.6917 24.2039C94.1185 24.5239 93.5056 24.686 92.8673 24.686C91.8892 24.686 91.0598 24.3489 90.4002 23.6819C89.7406 23.0162 89.4066 22.1756 89.4066 21.1842C89.4066 20.1929 89.7406 19.3579 90.4002 18.6994C91.0598 18.0409 91.8892 17.7066 92.8673 17.7066C93.5127 17.7066 94.1284 17.8702 94.696 18.1945C95.2522 18.5116 95.6768 18.9227 95.9642 19.4305L95.9656 19.4333Z" fill="white"/>
<path d="M112.295 9.83679C111.812 9.83679 111.393 10.0132 111.048 10.3602C110.697 10.7115 110.527 11.1183 110.527 11.6019C110.527 12.0855 110.703 12.5208 111.048 12.8678C111.393 13.2149 111.812 13.3912 112.295 13.3912C112.778 13.3912 113.2 13.2149 113.548 12.8678C113.897 12.5208 114.074 12.0955 114.074 11.6019C114.074 11.1084 113.897 10.7073 113.548 10.3602C113.198 10.0132 112.776 9.83679 112.295 9.83679Z" fill="white"/>
<path d="M105.425 12.1296H102.622V15.2773H100.41V17.9527H102.622V22.6465C102.622 24.1172 103.02 25.2678 103.807 26.0643C104.592 26.8623 105.662 27.2662 106.987 27.2662C107.505 27.2662 107.972 27.2236 108.374 27.1382L108.488 27.114V24.5069L108.314 24.5439C108.097 24.5908 107.842 24.615 107.558 24.615C106.894 24.615 106.366 24.4486 105.989 24.1214C105.614 23.7971 105.425 23.2851 105.425 22.5995V17.9527H110.888V27.1268H113.668V15.2773H105.425V12.1296Z" fill="white"/>
<path d="M133.252 15.2772L130.177 23.14L127.092 15.2772H120.441V14.4324C120.441 13.7468 120.629 13.2348 121 12.9105C121.373 12.5833 121.899 12.4183 122.562 12.4183C122.853 12.4183 123.099 12.4411 123.293 12.4852L123.468 12.5264V9.91926L123.356 9.89366C122.95 9.80121 122.49 9.75427 121.991 9.75427C120.666 9.75427 119.597 10.1611 118.816 10.9618C118.033 11.7626 117.637 12.9147 117.637 14.3854V15.2772H115.413V17.9527H117.637V27.1268H120.439V17.9527H125.204L128.741 26.4384L126.615 31.2459H129.618L136.265 15.2772H133.252Z" fill="white"/>
<path d="M55.9153 20.2711H60.7588V22.3691C60.2959 23.049 59.6548 23.5781 58.8551 23.9422C58.0454 24.3106 57.1028 24.4983 56.0526 24.4983C54.4333 24.4983 53.0731 23.9365 52.0087 22.8285C50.9429 21.7191 50.4037 20.2967 50.4037 18.5984C50.4037 16.9002 50.9203 15.5404 51.9394 14.4623C52.9585 13.3856 54.272 12.8394 55.8431 12.8394C56.9188 12.8394 57.894 13.1167 58.7404 13.6658C59.55 14.1892 60.1049 14.8961 60.3894 15.7651L60.4092 15.8235H63.7099L63.6915 15.7225C63.55 14.97 63.2556 14.2432 62.8168 13.5648C62.3568 12.8522 61.7765 12.2235 61.09 11.6987C60.4035 11.1738 59.5967 10.7514 58.6895 10.4413C57.7822 10.1327 56.8254 9.9762 55.8431 9.9762C54.2706 9.9762 52.8184 10.3545 51.5303 11.1027C50.2409 11.8508 49.2147 12.8948 48.483 14.2062C47.7512 15.5177 47.3804 16.9955 47.3804 18.5984C47.3804 20.2014 47.754 21.6892 48.4901 23.0205C49.2246 24.3504 50.2536 25.4143 51.5487 26.1824C52.8424 26.949 54.296 27.3388 55.8686 27.3388C57.5939 27.3388 59.1764 26.9917 60.5691 26.3076C61.9576 25.6263 63.0093 24.6804 63.7042 23.4828L63.7156 23.4629V17.4549H55.9181V20.2711H55.9153Z" fill="white"/>
<path d="M82.2389 23.4756C82.0861 23.8241 81.8129 24.12 81.4208 24.3561C80.9184 24.6604 80.3083 24.8141 79.6105 24.8141C78.7075 24.8141 77.9573 24.568 77.3841 24.0815C76.9906 23.7487 76.6863 23.3178 76.4811 22.7986L76.4032 22.8299L76.474 22.7815C76.4542 22.7289 76.4344 22.6763 76.416 22.6222C76.4046 22.5881 76.3933 22.5525 76.382 22.5184C76.3664 22.4686 76.3508 22.4188 76.3339 22.352L76.324 22.315C76.3098 22.2581 76.2942 22.2026 76.2815 22.1443H85.2L85.2212 22.0248C85.2608 21.803 85.2806 21.4431 85.2806 20.9524C85.2806 19.8302 85.0188 18.8018 84.5036 17.8929C83.987 16.9855 83.2835 16.2729 82.4116 15.7793C81.5397 15.2843 80.5673 15.0326 79.5199 15.0326C78.4117 15.0326 77.3798 15.3028 76.4528 15.8376C75.5271 16.3724 74.7854 17.1149 74.2504 18.0451C73.7154 18.9753 73.4436 20.0165 73.4436 21.1401C73.4436 22.2638 73.7154 23.3604 74.2504 24.3091C74.7854 25.2593 75.5285 26.0145 76.4584 26.5522C77.3883 27.0898 78.4343 27.3629 79.5666 27.3629C81.0104 27.3629 82.2587 26.9803 83.275 26.2264C84.2191 25.5252 84.8235 24.5993 85.0825 23.4771H82.2418L82.2389 23.4756ZM76.8335 18.7577C77.0005 18.5316 77.1973 18.3353 77.4152 18.1746C77.9446 17.7877 78.6013 17.5914 79.3671 17.5914C80.1328 17.5914 80.7938 17.7976 81.3104 18.203C81.8044 18.5913 82.1186 19.1033 82.2432 19.7249H76.3579C76.4726 19.3238 76.6339 18.9924 76.8335 18.7577Z" fill="white"/>
</svg>

  );
};

export default FreeCard;
