import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import navbarimage from "assets/img/layout/Navbar.png";
import { BsArrowBarUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";

import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";

import avatar from "assets/img/avatars/avatar4.png";
import AI from "assets/img/avatars/AI.svg";
import { IoSettingsOutline } from "react-icons/io5";
import { BellIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
// import axios from 'axios';

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // const token = localStorage.getItem('token');
      // if (!token) {
      //   console.error('No token found in localStorage.');
      //   return;
      // }

      // Call API to delete session
      // await axios.post('/api/auth/logout/');

      // Clear localStorage
      localStorage.clear();

      // Redirect to login page
      navigate('/auth/sign-in');
      window.location.reload();
    } catch (error) {
      console.error('An error occurred during logout:', error);
    }
  };

  return (
    <nav className=" flex flex-row flex-wrap items-center justify-between  bg-GreatifyNeutral-100 border-b border-GreatifyNeutral-200 px-4  h-[80px] w-full">
      <div className="">
       <p className="capitalize text-GreatifyChalkGreen-500 ">Welcome, {localStorage.getItem('username')} <span  className="uppercase text-GreatifyNeutral-400 font-semibold text-xs ml-1">( {localStorage.getItem('role')} )</span></p>
      </div>


<div className="flex gap-4">
      <div className="flex  items-center rounded-md  bg-white w-[400px] h-10 border overflow-hidden border-GreatifyNeutral-200 ">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-GreatifyChalkGreen-500 " />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full   text-sm font-medium text-GreatifyChalkGreen-500  placeholder:text-GreatifyNeutral-300 outline-none "
          />
        </div>

      <div className="flex items-center gap-4">
       
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>

        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full cursor-pointer" 
              src={AI}
              alt="AI"
            />
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-md bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500">
             <p className="p-4">AI here</p>

           
            </div>
          }
          classNames={"py-2 top-10 right-0 w-max"}
        /> 
        {/* start Notification */}
        <Dropdown
          button={
            <div className="cursor-pointer h-10 w-10 bg-white rounded-full">
            <BellIcon className=" w-full h-full p-2 text-GreatifyChalkGreen-500" />
          </div>
          }
          animation="origin-[0%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-md bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  Notification
                </p>
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  Mark all read
                </p>
              </div>

              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>

              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>
            </div>
          }
          classNames={"py-2 top-10 right-0 w-max"}
        />
        {/* start Horizon PRO */}
        <Dropdown
          button={
            <div className="cursor-pointer h-10 w-10 bg-white rounded-full">
              <Cog6ToothIcon className=" w-full h-full p-2 text-GreatifyChalkGreen-500" />
            </div>
          }
          children={
            <div className="flex w-[350px] flex-col gap-2 rounded-md bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div
                style={{
                  backgroundImage: `url(${navbarimage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                className="mb-2 aspect-video w-full rounded-lg"
              />
              <a
                target="blank"
                href="https://horizon-ui.com/pro?ref=live-free-tailwind-react"
                className="px-full linear flex cursor-pointer items-center justify-center rounded-xl bg-brand-500 py-[11px] font-bold text-white transition duration-200 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Buy Horizon UI PRO
              </a>
              <a
                target="blank"
                href="https://horizon-ui.com/docs-tailwind/docs/react/installation?ref=live-free-tailwind-react"
                className="px-full linear flex cursor-pointer items-center justify-center rounded-xl border py-[11px] font-bold text-navy-700 transition duration-200 hover:bg-gray-200 hover:text-navy-700 dark:!border-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-white/10"
              >
                See Documentation
              </a>
              <a
                target="blank"
                href="https://horizon-ui.com/?ref=live-free-tailwind-react"
                className="hover:bg-black px-full linear flex cursor-pointer items-center justify-center rounded-xl py-[11px] font-bold text-navy-700 transition duration-200 hover:text-navy-700 dark:text-white dark:hover:text-white"
              >
                Try Horizon Free
              </a>
            </div>
          }
          classNames={"py-2 top-10 right-0 w-max"}
          animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
        />
      
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full cursor-pointer" 
              src={avatar}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-md bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                  👋 Hey, {localStorage.getItem('username')}
                  </p>{" "}
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col p-4">
                <a
                  href=" "
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in"
                  onClick={handleLogout}
                >
                  Log Out
                </a>
              </div>
            </div>
          }
          classNames={"py-2 top-10 right-0 w-max"}
        />
      </div>
      </div>
    </nav>
  );
};

export default Navbar;
